.land-card-1 {
    position: relative;
}

.land-card-1>img {
    position: absolute;
    right: 20px;
    top: 20px;
    transform: scale(0.9);
}

.land-card-2 {
    position: relative;
}

.land-card-2>img:first-of-type {
    position: absolute;
    right: 20px;
    top: 20px;
    transform: scale(0.9);
}

.land-card-3 {
    position: relative;
}

.land-card-3>img:first-of-type {
    position: absolute;
    right: 20px;
    top: 20px;
    transform: scale(0.9);
}