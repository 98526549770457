*:focus {
    box-shadow: none !important;
}

@font-face {
    font-family: "Moon";
    src: url('assets/fonts/moon_thin.ttf') format('truetype'),
        url('assets/fonts/moon_regular.ttf') format('truetype'),
        url('assets/fonts/moon_bold.ttf') format('truetype');
}

.wrapper {
    background: linear-gradient(180deg,rgba(255,148,43,0) 81.25%,rgba(238, 0, 0, 1)) border-box,linear-gradient(180deg,rgba(238, 0, 0, 1),rgba(255,148,43,0) 15.48%) border-box;
    border: 2px solid transparent;
    border-radius: 2px;
}

.web3modal-modal-lightbox { 
    z-index: 10 !important; 
}

body {
    overflow-x: hidden;
}